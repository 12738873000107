<template>
  <div>
    <van-form ref="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" placeholder="请填写姓名" readonly label="姓名" />
        <van-field v-model="form.stuNo" placeholder="请填写学号" readonly label="学号" />
        <van-field v-model="form.tutorName" readonly label="辅导员姓名" />
        <van-field v-model="form.className" placeholder="请填写班级" readonly label="班级" />
        <custom-radio v-model="form.sex" label="性别" readonly valueKey="label" :columns="sexDict" />
        <van-field v-model="form.nationality" readonly label="民族">
          <template #input>
            {{codeConvertLabel(form.nationality,dict.nation_roman_alph_name)  }}
          </template>
        </van-field>
        <van-field v-model="form.politicalStatus" readonly label="政治面貌">
          <template #input>
            {{codeConvertLabel(form.politicalStatus,dict.ctry_base_polit_stat)  }}
          </template>
        </van-field>
        <custom-select ref="accountType" required v-model="form.accountType" placeholder="请选择户口类别" label="户口类别" :columns="dict.ctry_comn_household_cls" />
        <regional-cascader required ref="bornAddress" v-model="form.bornAddress" placeholder="请选择生源地" :changeLevel="3" label="生源地" />
        <van-field :rules="[{ required: true, message: '请填写电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" required v-model="form.phone" placeholder="请填写电话" label="联系电话" />
      </van-cell-group>
      <!-- 岗位信息 -->
      <van-cell-group title="岗位信息">
        <custom-select ref="applyJob" required v-model="form.applyJob" placeholder="请选择申请岗位" label="申请岗位" valueKey="id" labelKey="jobName" :columns="jobList" @change="selectApplayJobHandle" />
        <custom-radio v-model="form.obeyAllocte" label="是否服从分配" :columns="isDict" />
        <van-field v-model="jobInfo.jobName" readonly label="岗位名称"> </van-field>
        <van-field v-model="jobInfo.jobType" readonly label="岗位类型">
          <template #input>
            {{ jobInfo.jobType==='1'?"固定岗位":"临时岗位" }}
          </template>
        </van-field>
        <van-field v-model="jobInfo.jobDemand" readonly label="岗位需求人数"> </van-field>
        <van-field v-model="jobInfo.workStartAt" readonly label="岗位开始时间"> </van-field>
        <van-field v-model="jobInfo.workEndAt" readonly label="岗位结束时间"> </van-field>
        <van-field v-model="jobInfo.unitName" readonly label="工作部门"> </van-field>
        <van-field v-model="jobInfo.workPlace" readonly label="工作地点"> </van-field>
        <van-field v-model="jobInfo.workContent" readonly label="工作内容"> </van-field>
      </van-cell-group>
      <!--经济认定情况  -->
      <van-cell-group title="经济认定情况">
        <custom-date-picker v-model="form.pksApprovedAt" label="贫困认定时间" placeholder="选择贫困认定时间" />
        <custom-select ref="pksLevel" v-model="form.pksLevel" placeholder="请选择贫困等级" label="贫困等级" :columns=" pKDict" />
        <van-field v-model="form.monthConsume" label="在校每月平均消费" placeholder="请填写在校每月平均消费"> </van-field>
        <custom-date-picker v-model="form.loanAt" label="助学贷款时间" placeholder="选择助学贷款时间" />
        <van-field v-model="form.loanAmount" label="助学贷款总金额" placeholder="请填写助学贷款总金额"> </van-field>
      </van-cell-group>
      <!-- 在校期间获奖助情况-->
      <van-cell-group title="在校期间获奖助情况">
        <!-- 家庭成员情况 -->
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.stuJobAwardRelateds" :key="index" :title="item.awardProName" :value="item.awardAmount" :name="index">
            <div> <span class="collapse-title">获奖时间:</span><span class="collapse-text">{{item.awardDate}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="familyDelete(index,item.id)">删除</van-button>
            <van-button style="height:26px;margin-left: 5px;margin-top: 10px;" round type="primary" plain hairline @click="familyEdit(item,index)">编辑</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" native-type="button" size="small" style="height:36px" @click="familyAdd">添加获奖信息</van-button>
        </div>
      </van-cell-group>
      <!-- 勤工助学时间（空余时间打“√”） -->
      <van-cell-group title="勤工助学时间（空余时间打“√”）" style="margin-bootom:64px">
        <div class="card">
          1.星期一
          <div class="class-item">
            <van-checkbox v-for="item in addInitData" :key="item.courseAt" v-model="item.monday" icon-size="21px" shape="square">{{item.courseAt}}</van-checkbox>
          </div>
        </div>
        <div class="card">
          2.星期二
          <div class="class-item">
            <van-checkbox v-for="item in addInitData" :key="item.courseAt" v-model="item.tuseday" icon-size="21px" shape="square">{{item.courseAt}}</van-checkbox>
          </div>
        </div>
        <div class="card">
          3.星期三
          <div class="class-item">
            <van-checkbox v-for="item in addInitData" :key="item.courseAt" v-model="item.wednesday" icon-size="21px" shape="square">{{item.courseAt}}</van-checkbox>
          </div>
        </div>
        <div class="card">
          4.星期四
          <div class="class-item">
            <van-checkbox v-for="item in addInitData" :key="item.courseAt" v-model="item.thursdaty" icon-size="21px" shape="square">{{item.courseAt}}</van-checkbox>
          </div>
        </div>
        <div class="card">
          5.星期五
          <div class="class-item">
            <van-checkbox v-for="item in addInitData" :key="item.courseAt" v-model="item.friday" icon-size="21px" shape="square">{{item.courseAt}}</van-checkbox>
          </div>
        </div>
      </van-cell-group>
      <!-- 家庭成员信息弹出层 -->
      <van-popup v-model="familyInfoShow" position="bottom" closeable @close="familyclose" :style="{ height: ' 260px' }">
        <div class="van-popup-title-div"><b>{{isFamilyAdd?'新增获奖信息':'编辑获奖信息'}}</b></div>
        <van-form @submit="familySubmit">
          <van-field v-model="stuJobAwardRelateds.awardProName" placeholder="请填写获奖名称" required :rules="[{ required: true, message: '请填写获奖名称' }]" label="获奖名称" />
          <van-field v-model="stuJobAwardRelateds.awardAmount" placeholder="请填写获奖金额" required label="获奖金额" :rules="[{ required: true, message: '请填写获奖金额' }]" />
          <custom-date-picker v-model="stuJobAwardRelateds.awardDate" label="获奖日期" placeholder="选择获奖日期" />
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
          </div>
        </van-form>
      </van-popup>
      <div class="submit-button shadow-style" v-if="form.status === 1 || form.status === '1'">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit(true)">提 交</van-button>
        </div>
      </div>
      <div class="submit-save shadow-style" v-else>
        <van-button round block type="info" class="submit-save-savebtn" color="rgba(235, 123, 77, 1)" :loading="loading" @click="doSubmit(false)">保 存</van-button>
        <van-button round block type="primary" class="submit-save-subbtn" :loading="loading" @click="doSubmit(true)">提 交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getOption, getById } from '@/api/modules/daily/jobSetting'
import { add, edit, getDtoById } from '@/api/modules/daily/stuJobApply'
import { mapGetters } from 'vuex'
import { getStuinfo } from "@/api/modules/student/stuInfo";
import { codeConvertLabel, validCustom } from "@/utils/index";

export default {
  name: "StuJobApply",
  dicts: [
    'xj_xb',
    'daily_job_type',
    'ctry_base_polit_stat',
    'nation_roman_alph_name',
    'ctry_comn_household_cls',
  ],
  data() {
    return {
      customRuleFileds: ["accountType", "bornAddress", "applyJob"],
      form: {
        id: null,
        stuNo: null,
        stuName: null,
        phone: null,
        sex: null,
        nationality: null,
        politicalStatus: null,
        accountType: null,
        bornAddress: null,
        cardNo: null,
        unitNo: null,
        unitName: null,
        specCode: null,
        specName: null,
        gradeNo: null,
        classNo: null,
        className: null,
        jobType: null,
        applyJob: null,
        obeyAllocte: true,
        pksApprovedAt: null,
        pksLevel: null,
        monthConsume: null,
        loanAt: null,
        loanAmount: null,
        schoolYear: null,
        schoolTerm: null,
        tutorName: null,
        tutorNo: null,
        status: 0,
        stuJobAwardRelateds: [],
        stuJobCourseRelateds: [],
        applyJobName: null,
        applyJobUnitNo: null,
      },
      stuJobAwardRelateds: {},
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      pKDict: [
        { label: "非贫困生", value: "非贫困生" },
        { label: "建档立卡", value: "建档立卡" },
        { label: "特困生", value: "特困生" },
        { label: "一般贫困", value: "一般贫困" },
      ],
      activeNames: [0],
      jobList: [],
      jobInfo: {},
      fresh: true,
      loading: false,
      addInitData: [
        {
          courseAt: '一、二节',
          monday: false,
          tuseday: false,
          wednesday: false,
          thursdaty: false,
          friday: false,
        },
        {
          courseAt: '三、四节',
          monday: false,
          tuseday: false,
          wednesday: false,
          thursdaty: false,
          friday: false,
        },
        {
          courseAt: '五、六节',
          monday: false,
          tuseday: false,
          wednesday: false,
          thursdaty: false,
          friday: false,
        },
        {
          courseAt: '七、八节',
          monday: false,
          tuseday: false,
          wednesday: false,
          thursdaty: false,
          friday: false,
        },
      ],
      editIndex: 0,
      familyInfoShow: false,
      isFamilyAdd: false
    }
  },
  created() {
    this.$nextTick((e) => {
      getOption().then((res) => {
        this.jobList = res
      })

      if (this.$route.query && this.$route.query.id) {
        getDtoById(this.$route.query.id).then((res) => {
          this.form = { ...res }
          this.addInitData = res.stuJobCourseRelateds
          this.selectApplayJobHandle(res.applyJob)
        });
      } else {
        //学生新增
        getStuinfo({ stuNo: "" }).then((stu) => {
          if (stu) {
            this.updateFrom(stu);
          }
        });
      }
    });

  },
  methods: {
    codeConvertLabel,
    doSubmit(validate) {
      this.form.stuJobCourseRelateds = this.addInitData
      if (validate) {
        this.$refs.form.validate().then(
          (res) => {

            if (validCustom(this.customRuleFileds, this)) {
              // 验证通过数据提交



              // 验证通过数据提交
              this.$dialog
                .confirm({
                  title: "提示",
                  message: "你确定要进行提交操作吗？",
                })
                .then(() => {
                  this.loading = true;
                  let form = Object.assign({}, this.form);
                  form.status = 1;
                  if (this.$route.query && this.$route.query.id) {
                    this.toEdit(form)
                  } else {
                    this.toAdd(form)
                  }
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.$notify({
                type: "warning",
                message: "有提交项未填写！",
                duration: 1500,
              });
            }
          },
          (err) => {
            validCustom(this.customRuleFileds, this);
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        );
      } else {
        this.loading = true;
        let form = Object.assign({}, this.form);
        form.status = 0;
        if (this.$route.query && this.$route.query.id) {
          this.toEdit(form)
        } else {
          this.toAdd(form)
        }
      }
    },
    toEdit(form) {
      edit(form).then((res) => {
        this.$notify({
          message: "操作成功",
          type: "success",
          duration: 2500,
        });
        this.cancel();
        this.loading = false;
      });
    },
    toAdd(form) {
      add(form).then((res) => {
        this.$notify({
          message: "操作成功",
          type: "success",
          duration: 2500,
        });
        this.cancel();
        this.loading = false;
      });
    },
    cancel() {
      this.form = {
        id: null,
        stuNo: null,
        stuName: null,
        phone: null,
        sex: null,
        nationality: null,
        politicalStatus: null,
        accountType: null,
        bornAddress: null,
        cardNo: null,
        unitNo: null,
        unitName: null,
        specCode: null,
        specName: null,
        gradeNo: null,
        classNo: null,
        className: null,
        jobType: null,
        applyJob: null,
        obeyAllocte: true,
        pksApprovedAt: null,
        pksLevel: null,
        monthConsume: null,
        loanAt: null,
        loanAmount: null,
        schoolYear: null,
        schoolTerm: null,
        tutorName: null,
        tutorNo: null,
        status: 0,
        stuJobAwardRelateds: [],
        stuJobCourseRelateds: [],
        applyJobName: null,
        applyJobUnitNo: null,
      }
      this.$router.go(-1);
    },
    selectApplayJobHandle(id) {
      getById(id).then((res) => {
        this.jobInfo = res
        this.form.jobType = res.jobType
        this.form.applyJobName = res.jobName
        this.form.applyJobUnitNo = res.unitNo
      })
    },
    familyAdd() {
      this.isFamilyAdd = true;
      this.familyInfoShow = true;
    },
    familyEdit(data, index) {
      this.stuJobAwardRelateds = data;
      this.editIndex = index;
      this.isFamilyAdd = false;
      this.familyInfoShow = true;
    },
    familyDelete(index, id) {
      this.form.stuJobAwardRelateds.splice(index, 1);
    },
    familyclose() {
      this.familyInfoShow = false;
      this.stuJobAwardRelateds = {
        awardDate: '',
        awardProName: '',
        awardAmount: '',
      };
    },
    familySubmit() {
      if (this.isFamilyAdd) {
        this.form.stuJobAwardRelateds.push(this.stuJobAwardRelateds);
      } else {
        this.form.stuJobAwardRelateds.splice(this.editIndex, 1, this.stuJobAwardRelateds);
      }
      this.familyclose();
    },
    updateFrom(stuInfo) {
      this.form.unitNo = stuInfo.collNo // 院系代码
      this.form.unitName = stuInfo.collName // 院系名称
      this.form.specCode = stuInfo.sqecCode // 专业代码
      this.form.specName = stuInfo.sqecName // 专业名称
      this.form.classNo = stuInfo.classNo // 班级号
      this.form.className = stuInfo.className // 班级名称
      this.form.stuNo = stuInfo.stuNo // 学号
      this.form.stuName = stuInfo.stuName // 姓名
      this.form.sex = stuInfo.sexCode // 性别码
      this.form.sexName = stuInfo.sexName // 性别名称
      this.form.cardNo = stuInfo.cardNo // 身份证号
      this.form.nationality = stuInfo.nationCode //民族
      this.form.politicalStatus = stuInfo.polCode //政治面貌
      this.form.gradeNo = stuInfo.gradeNo
      this.form.tutorName = stuInfo.conslrStaffName //辅导员姓名
      this.form.tutorNo = stuInfo.conslrStaffNo //辅导员卡号
    },
  }
}
</script>

<style lang="scss" scoped>
.class-item {
  display: flex;
  padding: 5px 10px;
  flex-wrap: wrap;
  text-align: center;
}
.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}
.textarea-style {
  height: 100% !important;
}

.card {
  padding: 10px;
}
::v-deep.van-checkbox {
  width: 50%;
  margin: 5px 0;
}

::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  text-align: center;
  &-subbtn {
    margin-left: 5%;
  }

  .van-button {
    margin-top: 10px;
    margin-bottom: 9px;
    width: 40%;
  }

  .van-button--block {
    display: inline-block !important;
  }
}
</style>